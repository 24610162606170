import Fuse from 'fuse.js'
import { useDeferredValue, useMemo } from 'react'

export default function useSearch<T>(documents: T[], query: string, options: Fuse.IFuseOptions<T>): T[] {
  const fuse = useMemo(() => new Fuse<T>(documents ?? [], options), [documents, options])

  const deferredQuery = useDeferredValue(query)
  const results = useMemo(() => fuse.search(deferredQuery), [fuse, deferredQuery])

  return query.length === 0 ? documents ?? [] : results.map(r => r.item)
}
