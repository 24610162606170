import { basicFetch, processResponse } from '.'
import { baseApiUrl } from '../config'
import { getToken } from '../services/auth'

export const getAziendaBrandImage = async (aziendaId: number): Promise<Buffer> =>
  basicFetch(`/aziende/${aziendaId}/brandImage`)

export const updateAziendaBrandImage = async (data: { aziendaId: number; data: File }): Promise<void> => {
  const fd = new FormData()

  fd.append('brandImage', data.data)

  const token = getToken()

  const res = await fetch(`${baseApiUrl}/aziende/${data.aziendaId}/brandImage`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...(token ? { Authorization: `bearer ${token}` } : {})
    },
    body: fd
  })

  return await processResponse(res)
}
