export default function SearchInput({ query, setQuery }: { query: string; setQuery: (query: string) => void }) {
  return (
    <div className="relative">
      <input
        className="m-0 min-w-0 pr-8"
        type="text"
        value={query}
        onChange={e => {
          setQuery(e.target.value)
        }}
        placeholder="Cerca..."
      />
      {query && (
        <div className="absolute right-2 top-0 bottom-0 flex items-center">
          <button
            className="btn-neutral-icon p-[.125rem]"
            onClick={() => {
              setQuery('')
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>
      )}
    </div>
  )
}
