class Tree<T> {
  w: number
  h: number
  y: number
  c: Tree<T>[]

  x = 0
  prelim = 0
  mod = 0
  shift = 0
  change = 0
  leftThread: Tree<T> | null = null // Left thread
  rightThread: Tree<T> | null = null // Right thread
  extremeLeft: Tree<T> | null = null // extreme left nodes
  extremeRight: Tree<T> | null = null // extreme right nodes
  //sum of modifiers at the extreme nodes
  modSumLeft = 0
  modSumRight = 0

  constructor(width: number, height: number, y: number, children: Tree<T>[]) {
    this.w = width
    this.h = height
    this.y = y
    this.c = children
  }

  private setExtremes() {
    // Is leaf
    if (this.c.length === 0) {
      this.extremeLeft = this
      this.extremeRight = this
      this.modSumLeft = this.modSumRight = 0
    } else {
      this.extremeLeft = this.c[0].extremeLeft
      this.modSumLeft = this.c[0].modSumLeft
      this.extremeRight = this.c[this.c.length - 1].extremeRight
      this.modSumRight = this.c[this.c.length - 1].modSumRight
    }
  }

  private firstWalk() {
    if (this.c.length === 0) {
      this.setExtremes()
      return
    }

    this.c[0].firstWalk()
    let ih = updateIYL(this.c[0].extremeLeft!.bottom(), 0, null)
    for (let i = 1; i < this.c.length; i++) {
      this.c[i].firstWalk()
      this.seperate(i, ih)
      ih = updateIYL(this.c[i].extremeRight!.bottom(), i, ih)
    }
    this.positionRoot()
    this.setExtremes()
  }

  private positionRoot() {
    // Position root between children, taking into account their mod.
    this.prelim =
      (this.c[0].prelim +
        this.c[0].mod +
        this.c[this.c.length - 1].mod +
        this.c[this.c.length - 1].prelim +
        this.c[this.c.length - 1].w) /
        2 -
      this.w / 2
  }

  private bottom() {
    return this.y + this.h
  }

  private seperate(i: number, ih: IYL) {
    // Right contour node of left siblings and its sum of modifiers.
    let sr = this.c[i - 1]
    let mssr = sr.mod
    // Left contour node of right siblings and its sum of modifiers.
    let cl = this.c[i]
    let mscl = cl.mod
    while (sr !== null && cl !== null) {
      if (sr.bottom() > ih.lowY) {
        ih = ih.next!
      }
      // How far to the left of the right side of sr is the left side of cl.
      const distance = mssr + sr.prelim + sr.w - (mscl + cl.prelim)
      if (distance > 0) {
        mscl += distance
        this.moveSubtree(i, ih.index, distance)
      }

      const sy = sr.bottom()
      const cy = cl.bottom()
      if (sy <= cy) {
        sr = sr.c.length === 0 ? sr.rightThread! : sr.c[sr.c.length - 1]!

        if (sr !== null) {
          mssr += sr.mod
        }
      }
      if (sy >= cy) {
        cl = cl.c.length === 0 ? cl.leftThread! : cl.c[0]!
        if (cl !== null) {
          mscl += cl.mod
        }
      }
    }

    // Set threads and update extreme nodes.
    // In the first case, the current subtree must be taller than the left siblings.
    if (sr === null && cl !== null) {
      const li = this.c[0]!.extremeLeft!
      li.leftThread = cl
      // Change mod so that the sum of modifier after following thread is correct.
      const diff = mscl - cl.mod - this.c[0].modSumLeft
      li.mod += diff
      // Change preliminary x coordinate so that the node does not move.
      li.prelim -= diff
      // Update extreme node and its sum of modifiers.
      this.c[0].extremeLeft = this.c[i].extremeLeft
      this.c[0].modSumLeft = this.c[i].modSumLeft
    } else if (sr !== null && cl === null) {
      const ri = this.c[i].extremeRight!
      ri.rightThread = sr
      const diff = mssr - sr.mod - this.c[i].modSumRight
      ri.mod += diff
      ri.prelim -= diff
      this.c[i].extremeRight = this.c[i - 1].extremeRight
      this.c[i].modSumRight = this.c[i - 1].modSumRight
    }
  }

  private distributeExtra(i: number, si: number, distance: number) {
    // Are there intermediate children?
    if (si !== i - 1) {
      const nr = i - si
      this.c[si + 1].shift += distance / nr
      this.c[i].shift -= distance / nr
      this.c[i].change -= distance - distance / nr
    }
  }

  private moveSubtree(i: number, si: number, distance: number) {
    // Move subtree by changing mod.
    this.c[i].mod += distance
    this.c[i].modSumLeft += distance
    this.c[i].modSumRight += distance
    this.distributeExtra(i, si, distance)
  }

  private addChildSpacing() {
    let d = 0
    let modsumdelta = 0
    for (let i = 0; i < this.c.length; i++) {
      d += this.c[i].shift
      modsumdelta += d + this.c[i].change
      this.c[i].mod += modsumdelta
    }
  }

  private secondWalk(modsum: number) {
    modsum += this.mod
    // Set absolute (no-relative) horizontal coordinates.
    this.x = this.prelim + modsum
    this.addChildSpacing()
    this.c.forEach(c => c.secondWalk(modsum))
  }

  layout() {
    this.firstWalk()
    this.secondWalk(0)
  }
}

type Extremes = { extremeLeft: Tree<unknown>; extremeRight: Tree<unknown>; modSumLeft: number; modSumRight: number }

/* A linked list of the indexes of left siblings and their lowest vertical coordinate.
 */
class IYL {
  lowY: number
  index: number
  next: IYL | null

  constructor(lowY: number, index: number, next: IYL | null) {
    this.lowY = lowY
    this.index = index
    this.next = next
  }
}

function updateIYL(minY: number, i: number, ih: IYL | null) {
  // Remove siblings that are hidden by the new subtree.
  while (ih !== null && minY >= ih.lowY) {
    // Prepend the new subtree
    ih = ih.next
  }
  return new IYL(minY, i, ih)
}

export interface BoundingBox {
  addBoundingBox(width: number, height: number): { width: number; height: number }
  removeBoundingBox(x: number, y: number): { x: number; y: number }
}

export type SizedNode<T> = T & { width: number; height: number; children: SizedNode<T>[] }
export type PositionedNode<T> = T & {
  width: number
  height: number
  x: number
  y: number
  children: PositionedNode<T>[]
}

export class Layout {
  bb: BoundingBox

  constructor(boundingBox: BoundingBox) {
    this.bb = boundingBox
  }

  /**
   * Layout treeData.
   * Return modified treeData and the bounding box encompassing all the nodes.
   *
   * See getSize() for more explanation.
   */
  layout<T>(treeData: SizedNode<T>) {
    const tree = this.convert(treeData)!
    tree.layout()
    const { boundingBox, result } = this.assignLayout(tree, treeData)

    return {
      result,
      boundingBox
    }
  }

  /**
   * Returns Tree to layout, with bounding boxes added to each node.
   */
  convert<T>(treeData: SizedNode<T>, y = 0) {
    if (treeData === null) return null

    const { width, height } = this.bb.addBoundingBox(treeData.width, treeData.height)
    const children: Tree<T>[] = []
    if (treeData.children && treeData.children.length) {
      for (let i = 0; i < treeData.children.length; i++) {
        children[i] = this.convert(treeData.children[i], y + height)!
      }
    }

    return new Tree<T>(width, height, y, children)
  }

  /**
   * Assign layout tree x, y coordinates back to treeData,
   * with bounding boxes removed.
   */
  assignCoordinates<T>(tree: Tree<T>, treeData: PositionedNode<T>) {
    const { x, y } = this.bb.removeBoundingBox(tree.x, tree.y)
    treeData.x = x
    treeData.y = y
    for (let i = 0; i < tree.c.length; i++) {
      this.assignCoordinates(tree.c[i], treeData.children[i])
    }
  }

  /**
   * Return the bounding box that encompasses all the nodes.
   * The result has a structure of
   * { left: number, right: number, top: number, bottom: nubmer}.
   * This is not the same bounding box concept as the `BoundingBox` class
   * used to construct `Layout` class.
   */
  getSize<T>(treeData: PositionedNode<T>, box?: { left: number; right: number; top: number; bottom: number }) {
    const { x, y, width, height } = treeData
    if (!box) box = { left: x, right: x + width, top: y, bottom: y + height }

    box.left = Math.min(box.left, x)
    box.right = Math.max(box.right, x + width)
    box.top = Math.min(box.top, y)
    box.bottom = Math.max(box.bottom, y + height)

    if (treeData.children) {
      for (const child of treeData.children) {
        this.getSize(child, box)
      }
    }

    return box
  }

  /**
   * This function does assignCoordinates and getSize in one pass.
   */
  assignLayout<T>(
    tree: Tree<T>,
    treeData: SizedNode<T> | PositionedNode<T>,
    box?: { left: number; right: number; top: number; bottom: number }
  ) {
    const { x, y } = this.bb.removeBoundingBox(tree.x, tree.y)

    const out = treeData as PositionedNode<T>
    out.x = x
    out.y = y

    const { width, height } = treeData
    if (!box) {
      box = { left: x, right: x + width, top: y, bottom: y + height }
    }
    box.left = Math.min(box.left, x)
    box.right = Math.max(box.right, x + width)
    box.top = Math.min(box.top, y)
    box.bottom = Math.max(box.bottom, y + height)

    for (let i = 0; i < tree.c.length; i++) {
      this.assignLayout(tree.c[i], treeData.children[i], box)
    }

    return { result: treeData, boundingBox: box }
  }
}
