import { TBoardMetadata } from '@apb/database/BoardData'
import Link from 'next/link'

import Semaforo from './Semaforo'
import classNames from 'classnames'

export type BoardButtonProps = {
  userId: number
  boardMetadata?: TBoardMetadata
  className?: string
}

export default function BoardButton({ userId, boardMetadata, className }: BoardButtonProps) {
  return (
    <Link href={`/board/${userId}`}>
      <button className={classNames('btn-primary text-sm px-3 whitespace-nowrap', className)}>
        {boardMetadata ? (
          <>
            <i className="fas fa-eye" /> Vai alla Board
            {(boardMetadata.semaforo !== null || boardMetadata.progress !== null) && (
              <>
                <span className="border-l border-primary ml-2 mr-1 py-[.35em]"></span>
                {boardMetadata.semaforo !== null && (
                  <Semaforo value={boardMetadata.semaforo} className="ml-1 inline-block align-[-1px] w-3 h-3" />
                )}
                {boardMetadata.progress !== null && (
                  <span className="ml-1">{Math.round(boardMetadata.progress * 100)}%</span>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <i className="fas fa-plus" /> Crea la Board
          </>
        )}
      </button>
    </Link>
  )
}
