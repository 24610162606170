import { TBoardMetadata } from '@apb/database/BoardData'
import { Utente } from '@apb/database/schema'
import { ReactNode, useMemo } from 'react'

import BoardButton from '../components/BoardButton'
import Table from '../components/Table'
import UtenteOptions from '../components/UtenteOptions'
import { useAuthState } from '../atoms/auth'

interface ExtraColumn<T> {
  name?: string
  minWidth?: boolean
  render: (utente: T) => ReactNode
}

export interface UsersTableProps<T> {
  utenti: T[]
  onRowClick?: (userData: T) => void
  disableEditing?: boolean
  showBoard?: boolean
  hideDipartimento?: boolean
  mutate: () => void
  extraColumns?: Record<string, ExtraColumn<T>>
}

export type BaseUtente = Pick<
  Utente,
  'id' | 'nome' | 'cognome' | 'email' | 'emailSent' | 'dipartimento' | 'aziendaId' | 'role' | 'path' | 'parentId'
> & {
  boardMetadata: TBoardMetadata
}

export default function UsersTable<T extends BaseUtente>({
  utenti,
  disableEditing,
  showBoard,
  hideDipartimento,
  mutate,
  onRowClick,
  extraColumns
}: UsersTableProps<T>) {
  const columnsArr = useMemo(
    () => (extraColumns ? Object.entries(extraColumns).map(([key, obj]) => ({ ...obj, key })) : []),
    [extraColumns]
  )

  const auth = useAuthState()
  if (auth.state !== 'loggedIn') return null

  return (
    <Table>
      <thead>
        <tr>
          <th>Nome e Cognome</th>
          {columnsArr.map(({ name, key, minWidth }) => (
            <th key={key} className={minWidth ? 'w-0' : ''}>
              {name ?? ''}
            </th>
          ))}
          {!hideDipartimento && <th>Dipartimento</th>}
          <th>Email</th>
          {showBoard && <th className="w-0">Board</th>}
          <th className="w-0"></th>
        </tr>
      </thead>
      <tbody>
        {utenti.map(c => (
          <tr
            key={c.id}
            className={onRowClick ? 'cursor-pointer' : ''}
            onClick={
              onRowClick &&
              (() => {
                onRowClick(c)
              })
            }
          >
            <td>
              <span className="font-medium">
                {c.nome} {c.cognome}
              </span>
            </td>
            {columnsArr.map(({ render, key }) => (
              <td key={key}>{render(c)}</td>
            ))}
            {!hideDipartimento && (
              <td>{c.dipartimento ?? <span className="italic text-sm text-gray-500">Non assegnato</span>}</td>
            )}
            <td>{c.email ?? <span className="italic text-sm text-gray-500">Non assegnata</span>}</td>
            {showBoard && (
              <td>
                <div
                  onClick={
                    onRowClick &&
                    (e => {
                      e.stopPropagation()
                    })
                  }
                >
                  <BoardButton userId={c.id} boardMetadata={c.boardMetadata} />
                </div>
              </td>
            )}
            <td>{!disableEditing && c.id !== auth.user.id && <UtenteOptions utente={c} mutate={mutate} />}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
