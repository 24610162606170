import { Utente } from '@apb/database/schema'
import { UserDetails } from '@apb/shared/types'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import classNames from 'classnames'
import { FieldArray, Form, Formik, FormikState, useField } from 'formik'
import { useEffect, useMemo } from 'react'
import * as Yup from 'yup'

import { isEmail } from '../helpers'
import useDipartimentiOptions from '../hooks/useDipartimentiOptions'
import useLastValueMemoizedCallback from '../hooks/useLastValueMemoizedCallback'
import { trpc, trpcClient } from '../trpc'
import Card, { PopupCardHeader } from './Card'
import DipartimentoField from './DipartimentoField'
import ErrorField from './ErrorField'
import Spinner from './Spinner'

const newUtente = { nome: '', cognome: '', email: '', dipartimento: '' }

const debounceCheckEmail = AwesomeDebouncePromise(trpcClient.utenti.checkEmail.query, 500)

function AggiungiUtenteRow({
  remove,
  index,
  isLast,
  dipartimenti,
  isLoadingDipartimenti,
  hideDipartimento,
  isOnlyRemaining
}: {
  remove: () => void
  index: number
  isLast: boolean
  dipartimenti: string[]
  isLoadingDipartimenti: boolean
  hideDipartimento?: boolean
  isOnlyRemaining: boolean
}) {
  const fieldClasses = 'm-0 min-w-0 col-start-2 col-span-4 md:col-span-1'

  const memoizedCheckEmail = useLastValueMemoizedCallback(debounceCheckEmail)

  useField({
    name: `users[${index}].email`,
    validate: (email: string) => {
      if (email && isEmail(email))
        return memoizedCheckEmail({ email }).then(e => {
          if (e?.alreadyUsed) return 'Questa mail è già utilizzata'
        })

      return undefined
    }
  })

  return (
    <>
      {(!isLast || index === 0) && <span className="mt-1 text-gray-600 font-medium col-start-1">{index + 1}.</span>}
      <ErrorField
        name={`users[${index}].nome`}
        placeholder="Nome..."
        containerClassName={classNames(fieldClasses, 'md:col-start-2', isLast && 'opacity-50')}
        className="w-full"
      />
      <ErrorField
        name={`users[${index}].cognome`}
        placeholder="Cognome..."
        containerClassName={classNames(fieldClasses, 'md:col-start-3', isLast && 'opacity-50')}
        className="w-full"
      />
      <ErrorField
        name={`users[${index}].email`}
        placeholder="Email..."
        containerClassName={classNames(fieldClasses, 'md:col-start-4', isLast && 'opacity-50')}
        className="w-full"
      />
      {!hideDipartimento && (
        <DipartimentoField
          name={`users[${index}].dipartimento`}
          className={classNames(fieldClasses, 'md:col-start-5 mb-3', isLast && 'opacity-50')}
          dipartimenti={dipartimenti}
          isLoading={isLoadingDipartimenti}
        />
      )}
      {(!isLast || index === 0) && (
        <button
          className={classNames(hideDipartimento ? 'col-start-5' : 'col-start-6', ' self-baseline btn-danger-icon')}
          disabled={isOnlyRemaining}
          onClick={e => {
            e.preventDefault()
            remove()
          }}
        >
          <i className="far fa-trash-alt" />
        </button>
      )}
    </>
  )
}

function AutoRemoveLastRow({
  lastVirtualRowIndex,
  remove,
  isOnlyRemaining
}: {
  lastVirtualRowIndex: number
  remove: (i: number) => void
  isOnlyRemaining: boolean
}) {
  const [field] = useField<UserDetails>(`users[${lastVirtualRowIndex}]`)

  const isEmpty = useMemo(
    () => !field.value?.nome && !field.value?.cognome && !field.value?.dipartimento && !field.value?.email,
    [field.value]
  )

  useEffect(() => {
    if (isEmpty && !isOnlyRemaining) {
      remove(lastVirtualRowIndex)
      let newInputNameLastPart = 'nome'

      const activeElement = document.activeElement as HTMLInputElement
      if (activeElement?.name) {
        const split = activeElement.name.split('.')
        newInputNameLastPart = split[split.length - 1]
      }

      ;(
        document.querySelector(
          `input[name="users[${lastVirtualRowIndex - 1}].${newInputNameLastPart}"]`
        ) as HTMLInputElement
      )?.focus()
    }
  }, [isEmpty, remove, lastVirtualRowIndex, isOnlyRemaining])

  return null
}

function InnerAggiungiUtenteCard({
  values,
  isSubmitting,
  aziendaId,
  hideDipartimento,
  singleUser
}: {
  aziendaId: number
  hideDipartimento?: boolean
  singleUser?: boolean
} & Pick<FormikState<{ users: UserDetails[] }>, 'values' | 'isSubmitting'>): JSX.Element {
  const { dipartimenti, isLoading } = useDipartimentiOptions(
    aziendaId,
    values.users.map(u => u.dipartimento).filter((value): value is string => value !== undefined)
  )

  const isOnlyRemaining = values.users.length <= 1

  return (
    <FieldArray
      name="users"
      render={({ remove }) => {
        return (
          <Form>
            {!singleUser && (
              <AutoRemoveLastRow
                lastVirtualRowIndex={values.users.length - 1}
                remove={remove}
                isOnlyRemaining={isOnlyRemaining}
              />
            )}
            <div
              className="grid gap-x-3"
              style={{ gridTemplateColumns: hideDipartimento ? 'auto 2fr 2fr 3fr auto' : 'auto 2fr 2fr 3fr 3fr auto' }}
            >
              {(singleUser ? values.users : [...values.users, newUtente]).map((l, i) => (
                <AggiungiUtenteRow
                  key={i}
                  isLast={i === values.users.length}
                  isOnlyRemaining={isOnlyRemaining}
                  index={i}
                  remove={() => {
                    remove(i)
                  }}
                  dipartimenti={dipartimenti}
                  isLoadingDipartimenti={isLoading}
                  hideDipartimento={hideDipartimento}
                />
              ))}
            </div>

            <div className="text-center mt-3">
              <button type="submit" className="btn-success">
                {isSubmitting && <Spinner className="mr-2" />}Aggiungi
              </button>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default function AggiungiUtenteCard({
  aziendaId,
  parentId,
  handleClose,
  role,
  singleUser
}: {
  parentId: number | null
  handleClose: () => void
  aziendaId: number
  role: Utente['role']
  singleUser?: boolean
}): JSX.Element {
  const { mutateAsync: createUtente } = trpc.utenti.create.useMutation()

  const handleCreate = async (users: UserDetails[]) => {
    await createUtente({ users: users.map(u => ({ ...u, parentId, aziendaId, role })) })

    // switch (role) {
    //   case 'collaboratore':
    //     await handleCreateCollaboratore({ users, lineManagerId })
    //     break
    //   case 'lineManager':
    //     await handleCreateLineManager({ users, aziendaId })
    //     break
    //   case 'hr':
    //     await handleCreateAziendaAdmin({ users, aziendaId, type: AziendaAdminType.HR })
    //     break
    //   case 'direzione':
    //     await handleCreateAziendaAdmin({ users, aziendaId, type: AziendaAdminType.Direzione })
    //     break
    //   default:
    //     throw 'Invalid role!'
    // }
  }

  return (
    <Card className="max-w-3xl">
      <PopupCardHeader className="mb-3" handleClose={handleClose}>
        Aggiungi{' '}
        {
          {
            COLLABORATORE: 'Collaboratori',
            LINE_MANAGER: 'Line Manager',
            DIREZIONE: 'utenti alla Direzione',
            DIREZIONE_GENERALE: 'un Direttore Generale',
            HR: 'utenti alle Risorse umane'
          }[role]
        }
      </PopupCardHeader>
      <Formik<{ users: UserDetails[] }>
        initialValues={{ users: [newUtente] }}
        onSubmit={async values => {
          if (values.users.length === 0) return
          await handleCreate(values.users.map(u => ({ ...u, email: u.email?.length ? u.email : null })))
          handleClose()
        }}
        validationSchema={Yup.object().shape({
          users: Yup.array().of(
            Yup.object().shape({
              nome: Yup.string().min(2, 'Troppo corto').required('È richiesto'),
              cognome: Yup.string().min(2, 'Troppo corto').required('È richiesto'),
              email: Yup.string().email("L'email non é valida").nullable(),
              dipartimento: Yup.string().min(2, 'Troppo corto').nullable()
            })
          )
        })}
      >
        {({ values, isSubmitting }) => (
          <InnerAggiungiUtenteCard
            values={values}
            isSubmitting={isSubmitting}
            aziendaId={aziendaId}
            singleUser={singleUser}
          />
        )}
      </Formik>
    </Card>
  )
}
