/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import useSWR from 'swr'
import useMutation from 'use-mutation'

import { updateAziendaBrandImage } from '../../api/aziende'
import { useIsAdmin } from '../../hooks/useHasRole'
import { trpc } from '../../trpc'
import styles from './AziendaImage.module.scss'

const bufferToImageURI = (buf: Buffer): string => `data:image/jpeg;base64, ${buf.toString('base64')}`

function BufferImage({ buffer }: { buffer?: Buffer }) {
  const uri = useMemo(() => buffer && buffer.byteLength > 0 && bufferToImageURI(buffer), [buffer])

  return uri ? <img src={uri} alt="Brand Image" className="max-h-[5rem] float-right" /> : null
}

export default function AziendaImage({ aziendaId }: { aziendaId: number }): JSX.Element {
  const [isDragHover, setIsDragHover] = useState(false)
  const { data, error, mutate } = useSWR(`/aziende/${aziendaId}/brandImage`)

  const [handleUpdateAziendaBrandImage] = useMutation(updateAziendaBrandImage, { onSuccess: mutate })
  const { mutate: handleDeleteAziendaBrandImage } = trpc.aziende.deleteBrandImage.useMutation({
    onSuccess: () => {
      mutate()
    }
  })

  const hasImage = useMemo(() => !error && data instanceof ArrayBuffer, [data, error])

  const canEdit = useIsAdmin()

  return (
    <div
      className={classNames('w-64 relative', styles.AziendaImage)}
      onDragLeave={() => {
        setIsDragHover(false)
      }}
      onDragOver={e => {
        if (!canEdit) return
        e.preventDefault()
        e.stopPropagation()
        setIsDragHover(true)
      }}
      onDrop={e => {
        if (!canEdit) return
        e.preventDefault()
        e.stopPropagation()

        const { files } = e.dataTransfer

        if (files.length === 0) throw 'Error during file drop'

        setIsDragHover(false)
        handleUpdateAziendaBrandImage({ aziendaId, data: files[0] })
      }}
    >
      <BufferImage buffer={hasImage ? Buffer.from(data as ArrayBuffer) : undefined} />

      {canEdit && (
        <>
          <div
            className={classNames(
              hasImage
                ? `bottom-0 left-0 right-0 text-white bg-black bg-opacity-40 absolute ${styles.hasImage}`
                : 'border-2 border-dashed border-gray-300 text-gray-800',
              'px-2 py-1 text-sm'
            )}
          >
            {hasImage && (
              <div
                className={`text-gray-100 hover:text-red-600 cursor-pointer transition ${styles.hasImage}`}
                onClick={() => {
                  handleDeleteAziendaBrandImage({ aziendaId })
                }}
              >
                <i className="fas fa-trash" /> Rimuovi l&apos;immagine
              </div>
            )}
            <span>Trascina qui un immagine o </span>
            <label className="cursor-pointer underline text-gray-500 hover:text-gray-800 focus:text-gray-800 active:text-gray-900">
              <input
                className="hidden"
                type="file"
                id="logo"
                accept="image/png, image/jpeg, image/jpg"
                onChange={e => {
                  const { files } = e.target

                  if (!files || files.length === 0) throw 'Error during file selection'

                  handleUpdateAziendaBrandImage({ aziendaId, data: files[0] })
                }}
              />
              scegli un file
            </label>
          </div>

          <div
            className={classNames(
              'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black text-white bg-opacity-40 transition pointer-events-none',
              !isDragHover && 'opacity-0'
            )}
          >
            <p>Rilascia l&apos;immagine</p>
          </div>
        </>
      )}
    </div>
  )
}
