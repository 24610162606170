import styled from 'styled-components'

export default styled.table`
  width: 100%;

  tr > td {
    padding: 0.55em 0.5em;
    border-top: solid 1px #d0d0d0;
  }

  tr:first-child > td {
    border-top-width: 2px;
  }

  tbody > tr:hover {
    background-color: #f8f8f8;
  }

  th {
    text-align: left;
    padding: 0.4em 0.5em;
    color: #666;
  }
`
